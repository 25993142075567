<template>
    <div>
        <input hidden type="text" class="form-control" id="BookingOrderId" v-model="BookingOrderId">
        <div class="modal fade" id="BookingOrderModal" tabindex="-1" aria-labelledby="BookingOrderModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="BookingOrderModalLabel" class="font-weight-bold">{{ModalTitle}} {{this.BookingOrderId}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Kode Purchase Order</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="PurchaseOrderCode" v-model="PurchaseOrderCode" class="font-weight-bold" readonly/>
                                    <label id="errorPurchaseOrderCode" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                                
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Nama Item</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="ItemName" v-model="ItemName" class="font-weight-bold" readonly/>
                                    <label id="errorItemName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Satuan Dasar</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="BaseUom" v-model="BaseUom" class="font-weight-bold" readonly/>
                                    <label id="errorBaseUom" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                                
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Quantity Dasar</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="BaseQuantity" type="number" v-model="BaseQuantity" class="font-weight-bold" readonly/>
                                    <label id="errorBaseQuantity" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Satuan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="Uom" v-model="Uom" class="font-weight-bold" readonly/>
                                    <label id="errorUom" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                                
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Quantity Ordered</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="Quantity" type="number" v-model="Quantity" class="font-weight-bold" readonly/>
                                    <label id="errorQuantity" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Gudang</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="StorageId" v-model="StorageId" class="font-weight-bold" readonly/>
                                    <label id="errorStorageId" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                                
                                <CCol sm="3">
                                    <label class="form-label font-weight-bold">Quantity Ready</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="3">
                                    <CInput id="QuantityReady" type="number" v-model="QuantityReady" class="font-weight-bold" readonly/>
                                    <label id="errorQuantityReady" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                        </div>
                        <br>

                        <label id="errorItemGeneral" class="form-error" style="display: none; color: red;"></label>                        
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import BookingOrderDistributorServices from '../Script/BookingOrderDistributorServices.js';

export default {
    name: 'BookingOrderForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
    },
    props: ['reload', 'addItemClick'],
    data: function () {
        return {
            ModalTitle: '',

            BookingOrderId:'',
            PurchaseOrderId:'',
            PurchaseOrderCode:'',
            ItemId:'',
            ItemName:'',
            BaseUom:'',
            BaseQuantity: 0,
            Uom:'',
            Quantity: 0,
            PricePerUnit: 0,
            Price: 0,
            VatPerUnit: 0,
            Vat: 0,
            Discount1: 0,
            Discount2: 0,
            Discount3: 0,
            Discount4: 0,
            Discount5: 0,
            DiscountCash: 0,
            Discount1_amount: 0,
            Discount2_amount: 0,
            Discount3_amount: 0,
            Discount4_amount: 0,
            Discount5_amount: 0,
            DiscountCash_amount: 0,
            Tax_amount: 0,
            QuantityProduction: 0,
            QuantityReady: 0,
            StorageId : '',
            
            SaveType: '',
            Error: 0,
        }
    },
    methods: {
        async editClick(id){
            $(".form-error").css("display", "none");
            this.ModalTitle = 'View Booking Order';

            this.BookingOrderId = id;
            var data = await BookingOrderDistributorServices.getBookingOrderDetail(this.BookingOrderId);

            this.PurchaseOrderId = data.purchase_order_id;
            this.PurchaseOrderCode = data.purchase_order_number;
            this.ItemId = data.item_id;
            this.ItemName = data.item_name;
            this.BaseUom = data.base_uom;
            this.BaseQuantity = data.quantity_base;
            this.Uom = data.uom;
            this.Quantity = data.quantity;
            this.PricePerUnit = data.price_per_unit;
            this.Price = data.price;
            this.VatPerUnit = data.vat_per_unit;
            this.Vat = data.vat;
            this.Discount1 = data.discount1;
            this.Discount2 = data.discount2;
            this.Discount3 = data.discount3;
            this.Discount4 = data.discount4;
            this.Discount5 = data.discount5;
            this.DiscountCash = data.discount_cash;
            this.Discount1_amount = data.discount1_amount;
            this.Discount2_amount = data.discount2_amount;
            this.Discount3_amount = data.discount3_amount;
            this.Discount4_amount = data.discount4_amount;
            this.Discount5_amount = data.discount5_amount;
            this.DiscountCash_amount = data.discount_cash_amount;
            this.Tax_amount = data.tax_amount;
            
            this.QuantityProduction = data.quantity_production;
            this.QuantityReady = data.quantity_ready;

            this.StorageId = data.storage_id;

            window.$('#BookingOrderModal').modal('show');
        },
    }
}
</script>
