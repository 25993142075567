<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Booking Order Distributor</h2>
            </CCardHeader>
            <CCardBody>
                <booking-order-distributor-grid ref="grid" :key="gridReload" :viewClick="viewClick" />
                <booking-order-distributor-form ref="BookingOrderDistributorForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import BookingOrderDistributorGrid from './Grid/BookingOrderDistributorGrid.vue';
import BookingOrderDistributorForm from './Component/BookingOrderDistributorForm.vue';

export default {
    name: 'BookingOrder',
    components: {
        'booking-order-distributor-grid': BookingOrderDistributorGrid,
        'booking-order-distributor-form': BookingOrderDistributorForm,
    },
    async mounted () {
    },
    data(){
        return{
            gridReload: 0,
        }
    },
    methods: {
        viewClick(data){
            this.$refs.BookingOrderDistributorForm.editClick(data);
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>

<style scoped>
</style>