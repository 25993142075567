<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :editable="true"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:detailinit="detailInit"
            :resizable="true">
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { globalfunc } from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';
import BookingOrderDistributorServices from '../Script/BookingOrderDistributorServices.js';

export default {
    name: 'BookingOrderGridPerCustomer',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['viewClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            var swapField = [
                                {field: "product_code", new_field: "\"Item\".\"product_code\""},
                                {field: "item_name", new_field: "\"Item\".\"item_name\""},
                                {field: "customer_name", new_field: "\"PurchaseOrder__Customer\".\"contact_name\""},
                                {field: "storage_id", new_field: "\"PurchaseOrder\".\"storage_id\""},
                            ];
                            const paging = {
                                Page : {
                                    Limit: e.pageSize,Page: e.page
                                },
                                Filter : globalfunc.filterQueryBuilder(e.filter, swapField),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort, swapField)
                            }
                            const variables = {
                                paging : paging
                            }
                            return { 
                                query: BookingOrderDistributorServices.readBookingOrderQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if (response.data.GetBookingOrder == null) {
                            return [];
                        }
                        else {
                            return response.data.GetBookingOrder.booking_order_v;
                        }
                    },
                    total: function (response) {
                        if (response.data.GetBookingOrder == null) {
                            return 0;
                        }
                        else {
                            return response.data.GetBookingOrder.total;
                        }
                    },
                    model: {
                        fields: {
                            booking_order_id: { type: "number", editable: false },
                            purchase_order_number: { type: "string", editable: false} ,
                            customer_name: { type: "string", editable: false },
                            product_code: { type: "string", editable: false },
                            item_name: { type: "string", editable: false },
                            uom: { type: "string", editable: false },
                            quantity: { type: "number", editable: false },
                            quantity_ready: { type: "number", editable: true} ,
                            quantity_purchase_order: { type: "number", editable: false },
                            price_per_unit: { type: "number", editable: false },
                            vat: { type: "number", editable: false },
                            storage_id: { type: "string", editable: false },
                        }
                    }
                },
                group: [
                    {field: "purchase_order_number"},
                ]
            }),
            columns:  [
                { title: "Action", width: 200, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template: this.columnButton},
                { field: "booking_order_id", title: "Kode", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "purchase_order_number", title: "No. PO", width: 170, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    groupHeaderTemplate: "No. PO : #= value #"
                  },
                { field: "customer_name", title: "Customer", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "product_code", title: "Kode Barang", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "item_name", title: "Nama Barang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "uom", title: "UOM", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "quantity", title: "Booking", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "quantity_ready", title: "Qty App", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field: "quantity_purchase_order", title: "To PO", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                { field: "price_per_unit", title: "Harga", format: "{0:N2}", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    template: "#= kendo.toString(price_per_unit, 'n2') #"
                },
                { field: "vat", title: "Total", format: "{0:N2}", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" },
                    template: "#= kendo.toString(vat, 'n2') #"
                },
                { field: "storage_id", title: "Gudang", width: 160, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,
        };
    },
    mounted: async function() {
        var grid = this.$refs.grid.kendoWidget();
        var viewClick = this.$props.viewClick;
       
        var GridElement = grid.element;

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            viewClick(dataItem.booking_order_id);
        });
        
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButtonViewOnly("Booking Order Distributor")
        },
        detailInit: async function (e) {
            var data = BookingOrderDistributorServices.dataToArray(e.data);

            $('<div />').appendTo(e.detailCell).kendoGrid({
                ref: "BookingOrderDetailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(data);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if (response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if (response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                columns: [
                    { field: 'type_detail_id', title: "Tipe", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'created_at', title: "Tanggal", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                        template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'dd MMMM yyyy') #"
                    },
                    { field: 'created_by', title: "User", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: "quantity", title: "Booking", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                    { field: "quantity_ready", title: "Qty App", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                    { field: "quantity_purchase_order", title: "Qty To PO", width: 120, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" }},
                ],
            });
        },
    }
}
</script>